<template>
    <div class="role-index">
         <navigationBar :routerList="this.$router.history.current.meta.breadcrumb" 
          @buttonClickEmit0="addRole" 
          :buttonList="buttonList"/>
      <!--   <el-button   type="success" style="margin-bottom: 5px" slot="header"  @click="addRole()" size="mini"> 新增</el-button> -->
        <el-table :data="tableData" style="width: 100%" >
            <el-table-column prop="idNo" label="序号" width="60px;">
            </el-table-column>
            <el-table-column prop="name" label="角色名称" >
            </el-table-column>
            <el-table-column  label="操作" width="160px;">
            <template slot-scope="scope">
               <el-button  size="mini" type="success" @click="addRole(scope.row)">编辑</el-button>
               <el-button  size="mini" type="danger" @click="deleteRoleById(scope.row)">删除</el-button>
            </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="titleName" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
            <el-form ref="roleForm" :model="roleForm"  :rules="rules" >
                <el-form-item label="角色名称" prop="name" label-width="70px">
                    <el-input  v-model="roleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort" label-width="70px">
                    <el-input  v-model.number="roleForm.sort"></el-input>
                </el-form-item>
                <el-form-item >
                    <thead style="width: 50%;float: left;">
                        <tr>
                            <th class="thtitle" ><span  class="thSpan">角色菜单</span></th>
                        </tr>
                        <el-tree  
                            :data="menusData"
                            show-checkbox
                            node-key="id"
                            ref="tree"
                            :default-checked-keys="checkedUsers" 
                            :props="defaultProps">
                        </el-tree>
                    </thead>

                    <el-table :data="permission"  style="width: 50% ;float: left;"  @selection-change="handleSelectionPermisChange" ref="allPermissionTable">
                        <el-table-column  type="selection"  width="55" style="border-left: 1px solid red;"> </el-table-column>
                        <el-table-column prop="title" label="角色权限">
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="submitRole()" size="mini" :loading="loadingRole">提交</el-button>
            </span>
        </el-dialog>
        <div class="Pagination" style="text-align: right;margin-top: 10px;">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="count">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import navigationBar from '@/components/navigationBar'
    import {deleteRoleById,addOrUpdateRole,findDataByName,getAllRole,getAllMenus,getPermissionList,getRolePermission,getRoleMenu
     } from '@/api/getUcenterData'
    import {getTreeMenus} from '@/api/getUcenterData'
      import setting from '@/utils/config/index.js'
    export default {
        name:"Role",
        data () {
            return {

                options:{
                    border:true
                },
                menusData:[],
                titleName:'',
                tableData: [],
                dealCode:[],
                checkedRow:[],
                loading: false,
                dialogVisible:false,
                roleForm:{
                    id:'',
                    name:'',
                    sort:'',
                },
                currentPage: 0,
                pageSize: 10,
                count: 0,
                allMenu:[],
                permission:[],
                checkedRowMenu:[],
                checkedRowPermis:[],
                rules:{
                    sort:[ { required: true, message: '排序不能为空'},
                     { type: 'number', message: '排序必须为数字值'}]
                },
                buttonList:[
                  {
                    backgroundColor:'#52D1AF',
                    icon:'/static/img/add.png',
                    text:'新增',
                  }                  
                ],
                defaultProps: {
                  children: 'children',
                  label: 'name'
                },
                menusByPId:[],
                loadingRole:false,
                checkedUsers:[],
                systemNodeFlag:false
            }
        },
        created () {
            this.getRoleList();
            this.initList();
            this.getTreeMenus();
        },
        components:{
          navigationBar
        },
        methods: {
            
            handleClose(){
                this.dialogVisible = false;
            },
            addRole:function(info = null){
                console.log("info",info)
                this.dialogVisible = true
                // if(this.$refs.allMenuTable){
                //     this.$refs.allMenuTable.clearSelection();
                // }
                if(this.$refs.tree){
                    this.$refs.tree.setCheckedKeys([]);
                }
                if(this.$refs.allPermissionTable){
                    this.$refs.allPermissionTable.clearSelection();
                }
                if(info == null){
                    this.roleForm = {id:"" , name :"", sort :""};
                    this.titleName='新增'
                    // this.$refs['roleForm'].resetFields();
                }else{
                    this.titleName='编辑'
                    this.roleForm.name = info.name;
                    this.roleForm.id = info.id;
                    this.roleForm.sort = info.sort;
                    //获取角色菜单
                    this.getRoleMenu(info);
                    //获取角色权限
                    this.getRolePermission(info);
                }
        
            },
            getTreeMenus:function(){
                getTreeMenus( ).then((resData)=>{
                      console.log("getTreeMenus",resData)
                     if(resData.resultCode==1){
                        let  data = resData.data;
                        this.menusData = data
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }

                });
            },
            getChildrenNodes(permissionsList, LimitData) {
                // 退出递归标志
                let isExit = false
                for (let j = 0; j < LimitData.length; j++) {
                  if (LimitData[j].children.length>0) {
                  // 如果有children，那么说明它是父级，此时不将他加入checkedUsers
                    isExit = false
                    this.getChildrenNodes(permissionsList, LimitData[j].children)
                  } else {
                  // 当它是子级时再进行匹配
                    for (let i = 0; i < permissionsList.length; i++) {
                      if (LimitData[j].id == permissionsList[i].menu_id) {
                        // 菜单权限匹配
                        this.checkedUsers.push(LimitData[j].id)
                       // this.$refs.tree.setChecked(LimitData[j].id)
                      } else {
                      // 如果是子级，且没有匹配成功，则设置标识
                        isExit = true
                      }
                    }
                  }
                }
                if (isExit) { // 循环完成后进行判断
                  return false
                }
            },
            //获取角色菜单
            getRoleMenu:function(info){
                this.checkedUsers = []
                getRoleMenu({role_id:info.id}).then((resData)=>{
                    if(resData.resultCode==1){
                        let  data = resData.data;
                        this.getChildrenNodes(data,this.menusData)
                        let arr = this.checkedUsers
                        let menuArr =[]
                        for (var i = 0; i < arr.length; i++) {
                            menuArr.push(arr[i])
                        }
                        this.checkedUsers = menuArr 
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }
                });
            },
            //获取角色权限
            getRolePermission:function(info){
                getRolePermission({role_id:info.id}).then((resData)=>{
                    console.log("getRolePermission",resData.resultCode)
                    if(resData.resultCode==1){
                        let dataP = resData.data;
                        for(var i = 0 ; i < this.permission.length ; i++){
                        for(var j = 0 ; j < dataP.length; j++){
                            if(this.permission[i].id == dataP[j].permission_id){
                            this.$refs.allPermissionTable.toggleRowSelection(this.permission[i]);
                            }
                        }
                        }   
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }
                });
            },
            getRoleList:function(){
                let param = {
                    pageNo: this.currentPage,
                    pageSize: this.pageSize,
                };
                getAllRole(param).then((resData)=>{
                    console.log("getAllRole",resData.resultCode)
                    if(resData.resultCode==1){
                        var userList = resData.data.list
                        for(var i = 0 ; i < userList.length; i++){
                            userList[i].idNo = (this.currentPage - 1) * this.pageSize + i+1;  
                        }
                        this.count = userList.total;
                        this.tableData = userList;
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }

                })
            },
            handleSelectionMenuChange:function(value){
                //获取选中
                this.checkedRowMenu = value;
            },
            handleSelectionPermisChange:function(value){
                //获取选中
                this.checkedRowPermis = value;
            },
            handleCurrentChange:function(currentPage){
                this.currentPage = currentPage;
                 this.getRoleList();
            },
            getAllMenus:function(resolve){
                //全部菜单
                getAllMenus().then((resData)=>{
                    console.log("getAllMenus", resData.data)
                    if(resData.resultCode==1){
                        var res = resData.data
                         resolve(res);
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }
                })
            },
            getPermissionList:function(resolve){
                //全部权限
                getPermissionList( ).then((resData)=>{
                            console.log("getAllMenus", resData.data)
                    if(resData.resultCode==1){
                        var res = resData.data
                         resolve(res);
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }

                })
            },
            initList:function(){
                let  a = new Promise(this.getAllMenus);
                let  b = new Promise(this.getPermissionList);
                Promise.all([a,b]).then((res)=>{
                  let allMenu = res[0];
                  let permission = res[1];
                  this.allMenu = allMenu;
                  this.permission = permission;
                });
            },
            submitRole:function(){
                this.$refs.roleForm.validate((valid) => {
                    if (valid) {
                        let role = this.roleForm;
                        let arrPer = [];
                        let arrMenu = [];
                        for (var i = 0; i < this.checkedRowPermis.length; i++) {
                            arrPer.push({permission_id:this.checkedRowPermis[i].id})
                        }
                    
                        //获取半状态节点的父节点值
                        let pId = this.$refs.tree.getHalfCheckedKeys();
                        console.log("pIdpIdpIdpId",pId)
                        for (let j = 0; j < pId.length; j++) {
                            arrMenu.push({menu_id: pId[j]})
                        }
                        let menuNodes = this.$refs.tree.getCheckedNodes()
                        console.log("menuNodes",menuNodes)
                        for (var i = 0; i < menuNodes.length; i++) {
                            arrMenu.push({menu_id:menuNodes[i].id})
                        }
                        this.loadingRole = true;
                        this.$nextTick(function () {
                            addOrUpdateRole({ role:this.roleForm,roleMenuList:arrMenu,rolePermissionKeyList:arrPer}).then((res)=>{
                                if(res.resultCode == 1){
                                    this.$message({
                                        message: "成功",
                                        type: "success",
                                    });
                                    this.dialogVisible = false;
                                    this.loadingRole = false;
                                }else{
                                    this.$message({
                                        message: res.resultMsg,
                                        type: "error",
                                    });
                                }
                                this.getRoleList();
                               
                            })
                        })
                        

                    } 
                    
                });
               
            },
            deleteRoleById(row) {
                this.$confirm('确认删除该记录吗?', '提示', {
                type: 'warning'
              }).then(() => {
                let para = { id:row.id };
                deleteRoleById(para).then((res) => {
                     if(res.resultCode == 1){
                         this.$message({
                            message: "成功",
                            type: "success",
                        });
                     }else{
                         this.$message({
                            message: "失败",
                            type: "error",
                        });
                     }
                     this.getRoleList();
                });
              })

                     
            }
            

        }
    }
    
    
</script>
<style lang="scss">
.role-index{
    .el-table thead {
        color: #21293B;
        font-size: 0.8rem;
    }
    .el-table th, .el-table thead {
        background-color: #E9ECEE;
        height:60px;
    }
    .el-table {
        font-size: 0.8rem;
    }
    .el-table tr{
        background-color:rgba(253,253,255,1);
    }
    .paging{
       font-size: 0.8rem; 
       display: inline-block;
    }
    .el-pagination {
      display: inline-block
    }
    .img{
      width:20px;
      height:20px;
    }
    .el-dialog__header {
        background: #E9ECEE;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title{
        font-weight: bold;
    }
    .thtitle{
        background-color: #E9ECEE;
        width: 303px;
        height: 65px;
        font-size: 0.8rem;
        color: #21293B;
        text-align:left;
    }
    .thSpan{
        position: relative;
        top: 4px;
        left: 3rem;
    }
    .el-tree-node__content{
        height:47px;
        //border:1px solid #F5F7FA;
    }
    .el-tree-node__label{
        font-size:0.8rem;
        position:relative;
        left:0.7rem;
    }
    .el-table td, .el-table th.is-leaf{
        border-bottom:0px;
    }
    .el-table__body{
        table-layout:auto;
        border: 1px solid #F5F7FA;
    }
    th {
        label{
            position:relative;
            left:0.3rem;
        }
    }
    td {
        label{
            position:relative;
            top:-0.05rem;
        }
    }
    .el-tree{
        background-color:rgba(253,253,255,1);
        border:1px solid #F5F7FA;
    }
    
}
    
</style>